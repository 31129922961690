/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */
 
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */

.Checkout {
    padding-block-end: 30px;

    @include mobile {
        padding-block-end: 80px;
    }

    @include desktop {
        margin-block-start: 0px;
    }

    @include desktop {
        &::before {
            min-height: 0px;
        }

        &-Header {
            padding-block-start: 0px;
            align-items: flex-start;
            padding-block-end: 0px;
        }
    }

    &-Title {
        font-size: 24px;
        color: #0A0903;
    }

    &-Step {
        font-size: 16px;
        color: #6c6f7a;

        @include desktop {
            padding-block-start: 24px;
        }
    }

    &-SelectedStep,&-StepsBorder,&-TotalSteps {
        font-size: 16px;
    }

    .ExpandableContent-Button {
        align-items: flex-start;

        svg {
            margin-block-start: 25px;
        }
    }

    &-Heading {
        margin-block-start: 0px;
        font-size: 20px;
        text-transform: capitalize;
    }

    &-Wrapper {
        grid-template-columns: none;
    }

    &-InnerWrapper {
        &_multiColumn {
            .Checkout-Wrapper {
                grid-template-columns: repeat(2, 1fr);
            }
        }
    }

    &-StickyButtonWrapper {
        @include mobile {
            -webkit-border-before: 1px solid var(--primary-divider-color);
            border-top: 1px solid var(--primary-divider-color);
            position: fixed;
            width: 100%;
            z-index: 85;
            inset-block-end: var(--footer-total-height);
            background-color: var(--color-white);
            padding: 16px;
            margin-block-end: 20px;
        }
    }
}
